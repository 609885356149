import { useParams } from "react-router-dom";
import HelpTextPageView from "./HelpTextPageView";
import { useState } from "react";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useHelpTextContext } from "../../../context/HelpTextContext";
import { useRouteContext } from "../../../context/RouteContext";
import { useTinyEditor } from "../../../components/tinyEditor/TinyEditor";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import useEntityTranslations from "../../../hooks/useEntityTranslations";

function HelpTextPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { help_text } = useParams();
  const helpTextContext = useHelpTextContext();
  const [submitError, setSubmitError] = useState(null);
  const [helpTextData, setHelpTextData] = useState({
    name: "",
    path: "",
    show_in_subpaths: true,
    translations: [],
  });

  const translationsController = useEntityTranslations(
    helpTextData.translations,
    onTranslationsChange
  );

  const descriptionController = useTinyEditor(
    translationsController.getValue("description"),
    (value) => translationsController.onTranslationChange(value, "description")
  );

  /**
   * Initialize the component
   */
  useCustomEffect(() => {
    if (help_text) {
      fetchHelpTextData();
    }
  });

  /**
   * This method will fetch the helpText data from the API
   */
  async function fetchHelpTextData() {
    await callEvaApi(
      new EvaApiCall(`help_texts/${help_text}`)
        .setLoadingGroup("help_text_page_fetch")
        .setOnSuccess((response) => {
          const newData = response.data.data;
          setHelpTextData({
            name: newData.name,
            path: newData.path,
            show_in_subpaths: newData.show_in_subpaths,
            translations: newData.translations.map((translation) => ({
              language_id: translation.language,
              title: translation.title,
              description: translation.description,
            })),
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the code on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setHelpTextData({ ...helpTextData, name: e.target.value });
  }

  /**
   * This method will update the path on input change
   * @param {event} e
   */
  function onPathChange(e) {
    setHelpTextData({ ...helpTextData, path: e.target.value });
  }

  /**
   * This method will handle the show in subpaths change
   * @param {any} value
   */
  function onShowInSubpathsChange(value) {
    setHelpTextData((current) => ({
      ...current,
      show_in_subpaths: value,
    }));
  }

  /**
   * This method will handle the translations change
   * @param {array} value
   */
  function onTranslationsChange(value) {
    setHelpTextData((current) => ({
      ...current,
      translations: value,
    }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(help_text ? `help_texts/${help_text}` : "help_texts")
        .setLoadingGroup("help_text_page_submit")
        .setMethod(help_text ? "PUT" : "POST")
        .setData(helpTextData)
        .setErrorState(setSubmitError)
        .setOnSuccess(() => {
          routeContext.back();
          helpTextContext.fetchHelpTexts();
        })
    );
  }

  return (
    <HelpTextPageView
      helpTextData={helpTextData}
      descriptionController={descriptionController}
      translationsController={translationsController}
      onNameChange={onNameChange}
      onPathChange={onPathChange}
      onShowInSubpathsChange={onShowInSubpathsChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default HelpTextPage;
