import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../../styles/InputStyles";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../styles/LayoutStyles";
import { useAuthContext } from "../../../context/AuthContext";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import Select from "../../../components/select/main/Select";
import MultiSelect from "../../../components/select/main/multi/MultiSelect";
import LocationFormSelect from "../../../components/select/custom/location/form/LocationFormSelect";
import LanguageFormSelect from "../../../components/select/custom/language/form/LanguageFormSelect";

function UserPageView({
  user,
  userData,
  passwordUpdateOnly,
  roles,
  employees,
  onLocationIdChange,
  onNameChange,
  onEmailChange,
  onPasswordChange,
  onLanguageIdChange,
  onEmployeeIdChange,
  onRolesChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  return (
    <OverlayLoader groups="user_page_fetch">
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"user_page_save"}
      >
        {authContext.auth.user.company.type !== "superadmin" ? (
          <InputWrapper
            label={translate("eva.main.entities.location")}
            error={EVAApiHelper.getResponseError("location_id", submitError)}
            margin={`0px 0px ${theme.spacing.normal}`}
          >
            <LocationFormSelect
              value={userData.location_id}
              onChange={onLocationIdChange}
              disabled={user !== undefined || passwordUpdateOnly}
            />
          </InputWrapper>
        ) : (
          ""
        )}

        <InputWrapper
          label={translate("eva.main.validation.attributes.name")}
          error={EVAApiHelper.getResponseError("name", submitError)}
          required
        >
          <StyledInput
            type="text"
            placeholder={translate("eva.main.validation.attributes.name")}
            value={userData.name}
            onChange={onNameChange}
            disabled={passwordUpdateOnly}
          />
        </InputWrapper>

        <StyledFlexLayout margin={`${theme.spacing.normal} 0px`}>
          <StyledFlexLayoutItem>
            <InputWrapper
              label={translate("eva.main.validation.attributes.email")}
              error={EVAApiHelper.getResponseError("email", submitError)}
              required
            >
              <StyledInput
                type="email"
                placeholder={translate(
                  "eva.main.validation.placeholders.email"
                )}
                value={userData.email}
                onChange={onEmailChange}
                disabled={passwordUpdateOnly}
              />
            </InputWrapper>
          </StyledFlexLayoutItem>

          <StyledFlexLayoutItem>
            <InputWrapper
              label={translate("eva.main.validation.attributes.password")}
              error={EVAApiHelper.getResponseError("password", submitError)}
              required={!user}
            >
              <StyledInput
                type="password"
                placeholder={translate(
                  "eva.main.validation.placeholders.password"
                )}
                value={userData.password}
                onChange={onPasswordChange}
              />
            </InputWrapper>
          </StyledFlexLayoutItem>
        </StyledFlexLayout>

        <InputWrapper
          label={translate("eva.main.validation.attributes.language_id")}
          error={EVAApiHelper.getResponseError("language_id", submitError)}
          required
        >
          <LanguageFormSelect
            value={userData.language_id}
            onChange={onLanguageIdChange}
            disabled={passwordUpdateOnly}
          />
        </InputWrapper>

        {authContext.auth.user.company.type !== "superadmin" ? (
          <>
            <InputWrapper
              label={translate("eva.main.entities.employee")}
              margin={`${theme.spacing.normal} 0px 0px`}
              error={EVAApiHelper.getResponseError("employee_id", submitError)}
            >
              <Select
                options={[
                  { value: null, label: translate("eva.main.general.select") },
                  ...employees.map((employee) => {
                    return { value: employee.id, label: employee.name };
                  }),
                ]}
                value={userData.employee_id}
                onChange={onEmployeeIdChange}
                disabled={passwordUpdateOnly}
              />
            </InputWrapper>

            <InputWrapper
              label={translate("eva.main.entities.roles")}
              margin={`${theme.spacing.normal} 0px 0px`}
              error={EVAApiHelper.getResponseErrorArray(
                "roles",
                submitError,
                (index) => roles[index].name
              )}
            >
              <MultiSelect
                options={roles.map((role) => {
                  return { value: role.id, label: role.name };
                })}
                value={userData.roles}
                onChange={onRolesChange}
                disabled={passwordUpdateOnly}
              />
            </InputWrapper>
          </>
        ) : (
          ""
        )}
      </FormContainer>
    </OverlayLoader>
  );
}

export default UserPageView;
