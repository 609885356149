import moment from "moment";
import { useLanguageContext } from "../../context/LanguageContext";
import SchedulingFormView from "./SchedulingFormView";
import useCustomEffect from "../../hooks/useCustomEffect";

function SchedulingForm({ schedule, onScheduleChange, submitError }) {
  const { translate } = useLanguageContext();

  useCustomEffect(() => {
    correctSchedule();
  }, [schedule]);

  /**
   * This method will make sure that the given schedule is useable for the form
   * @returns {object}
   */
  function correctSchedule() {
    const correctedSchedule = {
      frequency: schedule?.frequency ?? null,
      interval: schedule?.interval ?? 1,
      start_date: schedule?.start_date ?? moment().format("YYYY-MM-DD"),
      days: schedule?.days ?? ["monday"],
      month_day: schedule?.month_day ?? 1,
      month_week: schedule?.month_week ?? "first",
      month_day_of_week: schedule?.month_day_of_week ?? "monday",
      year_month: schedule?.year_month ?? moment().format("MMMM").toLowerCase(),
      month_input_usage:
        schedule?.month_input_usage ??
        (schedule?.month_day_of_week ? "month_week" : "month_day"),
    };

    if (schedule) {
      for (const key in correctedSchedule) {
        if (correctedSchedule[key] !== schedule[key]) {
          onScheduleChange(correctedSchedule);
          break;
        }
      }
    } else {
      onScheduleChange(correctedSchedule);
    }
  }

  /**
   * This method will handle the functionality if the value of the frequency select has changed
   * @param {any} value
   */
  function onFrequencyChange(value) {
    onScheduleChange({ ...schedule, frequency: value });
  }

  /**
   * This method will handle the functionality if the value of the interval input has changed
   * @param {event} e
   */
  function onIntervalChange(e) {
    onScheduleChange({
      ...schedule,
      interval: Math.max(e.target.value, 1),
    });
  }

  /**
   * This method will handle the functionality for when the start date input has changed
   * @param {event} e
   */
  function onStartDateChange(e) {
    onScheduleChange({ ...schedule, start_date: e.target.value });
  }

  /**
   * This method will handle the onchange event for the days select
   * @param {array} values
   */
  function onDaysChange(values) {
    onScheduleChange({
      ...schedule,
      days: values,
    });
  }

  /**
   * This method will handle the onchange event for the month_day input
   * @param {event} e
   */
  function onMonthDayChange(e) {
    onScheduleChange({
      ...schedule,
      month_day: Math.min(Math.max(e.target.value, 1), 31),
    });
  }

  /**
   * This method will handle the onchange event for the month_week select
   * @param {any} value
   */
  function onMonthWeekChange(value) {
    onScheduleChange({ ...schedule, month_week: value });
  }

  /**
   * This method will handle the onchange event of the month_day_of_week input
   * @param {any} value
   */
  function onMonthDayOfWeekChange(value) {
    onScheduleChange({ ...schedule, month_day_of_week: value });
  }

  /**
   * This method will handle the onchange event of the year_month select
   * @param {any} value
   */
  function onYearMonthChange(value) {
    onScheduleChange({ ...schedule, year_month: value });
  }

  /**
   * This method will handle the onchange event of the month_input_usage  input.
   * @param {string} value
   */
  function onMonthInputChange(value) {
    onScheduleChange({ ...schedule, month_input_usage: value });
  }

  /**
   * This method will return the correct hint which will be used for the interval input
   * @returns {string}
   */
  function getIntervalHint() {
    if (!schedule) {
      return "";
    }

    switch (schedule?.frequency) {
      case "daily":
        return translate("eva.main.calendar.days");
      case "weekly":
        return translate("eva.main.calendar.weeks");
      case "monthly":
        return translate("eva.main.calendar.months");
      case "yearly":
        return translate("eva.main.calendar.years");
      default:
        return "";
    }
  }

  /**
   * This method will return all days of the week
   * @returns {object[]}
   */
  function getWeekDays() {
    moment.locale("en");
    return moment.weekdays().map((day) => day.toLowerCase());
  }

  /**
   * This method will return all year months
   * @returns {object[]}
   */
  function getYearMonths() {
    moment.locale("en");
    return moment.months().map((month) => month.toLowerCase());
  }

  return (
    <SchedulingFormView
      schedule={schedule}
      onFrequencyChange={onFrequencyChange}
      onIntervalChange={onIntervalChange}
      onDaysChange={onDaysChange}
      onMonthDayChange={onMonthDayChange}
      onMonthDayOfWeekChange={onMonthDayOfWeekChange}
      onMonthInputChange={onMonthInputChange}
      onMonthWeekChange={onMonthWeekChange}
      onStartDateChange={onStartDateChange}
      onYearMonthChange={onYearMonthChange}
      getIntervalHint={getIntervalHint}
      weekdays={getWeekDays()}
      yearmonths={getYearMonths()}
      submitError={submitError}
    />
  );
}

export default SchedulingForm;
