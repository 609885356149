import ImageSelectView from "./ImageSelectView";

function ImageSelect({
  images = [],
  value,
  onChange,
  style = { width: "170px", height: "170px" },
}) {
  value = value ? images.find((image) => image.id === value) : null;

  return (
    <ImageSelectView
      images={images}
      value={value}
      onChange={onChange}
      style={style}
    />
  );
}

export default ImageSelect;
