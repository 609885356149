import { useLanguageContext } from "../../../context/LanguageContext";
import VisitorPage from "../../../pages/visitors/visitor/VisitorPage";
import { useVisitorCreateRouteValidator } from "../../validators/visitors/VisitorCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useVisitorsRouteConfig from "./VisitorsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useVisitorCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/visitors/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.visitor"),
        },
      ])
    )
    .setValidator(useVisitorCreateRouteValidator())
    .setPage(<VisitorPage />)
    .setParent(useVisitorsRouteConfig());
}

export default useVisitorCreateRouteConfig;
