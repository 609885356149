import styled from "styled-components";
import { MultiStyle } from "../context/ThemeContext";

export const StyledButton = styled.button`
  ${(props) =>
    MultiStyle.new(props, "background-color")
      .setBack(props.theme.colors.accent.normal)
      .setFront(props.theme["settings.button.color.primary.background"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "color")
      .setBack(props.theme.colors.on.accent)
      .setFront(props.theme["settings.button.color.primary.text"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "padding")
      .setBack(props.theme.spacing.normal)
      .setFront(`${props.theme["settings.button.padding"]}px`)
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border-radius")
      .setBack(props.theme.radius.default)
      .setFront(`${props.theme["settings.button.border.radius"]}px`)
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border")
      .setFront(
        `1px solid ${props.theme["settings.button.color.primary.border"]}`
      )
      .get()}
  ${(props) => MultiStyle.new(props, "font-weight").setFront("bold").get()}

  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  opacity: ${(props) => (props.disabled ? 0.33 : 1)};
  cursor: pointer;

  &:hover {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setBack(props.theme.colors.accent.dark)
        .setFront(props.theme["settings.button.color.primary.background"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setBack(props.theme.colors.on.accent)
        .setFront(props.theme["settings.button.color.primary.text"])
        .get()}
    ${(props) => MultiStyle.new(props, "opacity").setFront("0.8").get()}
  }
`;

export const StyledAltButton = styled(StyledButton)`
  ${(props) =>
    MultiStyle.new(props, "background-color")
      .setBack(props.theme.colors.primary.lightest)
      .setFront(props.theme["settings.button.color.secondary.background"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "color")
      .setBack(props.theme.colors.on.primary)
      .setFront(props.theme["settings.button.color.secondary.text"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border")
      .setFront(
        `1px solid ${props.theme["settings.button.color.secondary.border"]}`
      )
      .get()}

  &:hover {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setFront(props.theme["settings.button.color.secondary.background"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setFront(props.theme["settings.button.color.secondary.text"])
        .get()}
  }
`;

export const StyledIconButton = styled(StyledButton)`
  padding: 0px;
  width: ${(props) => props.theme.container.size.normal};
  height: ${(props) => props.theme.container.size.normal};
  min-width: ${(props) => props.theme.container.size.normal};
  min-height: ${(props) => props.theme.container.size.normal};
`;

export const StyledAltIconButton = styled(StyledIconButton)`
  ${(props) =>
    MultiStyle.new(props, "background-color")
      .setFront(props.theme["settings.button.color.secondary.background"])
      .setBack(props.theme.colors.primary.lightest)
      .get()}
  ${(props) =>
    MultiStyle.new(props, "color")
      .setFront(props.theme["settings.button.color.secondary.text"])
      .setBack(props.theme.colors.on.primary)
      .get()}

  &:hover {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setFront(props.theme["settings.button.color.secondary.background"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setFront(props.theme["settings.button.color.secondary.text"])
        .get()}
  }
`;

export const StyledOutlinedIconButton = styled(StyledAltIconButton)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.on.primary};

  &:hover {
    background-color: initial;
    border-color: ${(props) => props.theme.colors.accent.dark};
    color: ${(props) => props.theme.colors.accent.dark};
  }
`;

export const StyledTextButton = styled.button`
  color: ${(props) => props.color};
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.accent.dark};
  }
`;
