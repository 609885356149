import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../../context/LanguageContext";
import IconSelect from "../../main/icon/IconSelect";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

function LocationSelectView({ options, value, onChange, disabled }) {
  const { translate } = useLanguageContext();

  return (
    <IconSelect
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
      title={translate("eva.main.entities.location")}
      icon={(value) => <FontAwesomeIcon icon={faLocationDot} />}
    />
  );
}

export default LocationSelectView;
