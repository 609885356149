import { useLanguageContext } from "../../context/LanguageContext";
import MailSettingsPage from "../../pages/mail_settings/index/MailSettingsPage";
import { useMailSettingsRouteValidator } from "../validators/MailSettingsRouteValidator";
import { RouteConfig } from "./RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useMailSettingsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/mail_settings")
    .setLabel(translate("eva.main.entities.mail_settings"))
    .setValidator(useMailSettingsRouteValidator())
    .setPage(<MailSettingsPage />);
}

export default useMailSettingsRouteConfig;
