import styled from "styled-components";
import { StyledAltButton } from "../../../../styles/ButtonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledMultiSelect = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledMultiSelectValues = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
  flex-wrap: wrap;
`;

export const StyledMultiSelectValue = styled(StyledAltButton)`
  background-color: ${(props) => props.theme.colors.primary.light};
  border-radius: ${(props) => props.theme.radius.small};
  padding-block: ${(props) => props.theme.spacing.small};
  font-size: ${(props) => props.theme.font.size.sm};
`;

export const StyledMultiSelectActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
  margin-top: ${(props) => props.theme.spacing.small};
`;

export const StyledMultiSelectClear = styled(FontAwesomeIcon)`
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.accent.dark};
  }
`;
