import { useParams } from "react-router-dom";
import QuestionnairePageView from "./QuestionnairePageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useState } from "react";
import { useRouteContext } from "../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function QuestionnairePage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { questionnaire } = useParams();
  const [questionnaireData, setQuestionnaireData] = useState({
    fetched: false,
    name: "",
    score: 0,
  });
  const [submitError, setSubmitError] = useState(null);

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    if (questionnaire) {
      fetchQuestionnaireData();
    }
  });

  /**
   * This method will fetch the publications data from the API
   */
  async function fetchQuestionnaireData() {
    await callEvaApi(
      new EvaApiCall(`questionnaires/${questionnaire}`)
        .setLoadingGroup("questionnaire_page_fetch")
        .setOnSuccess((response) => {
          setQuestionnaireData({
            fetched: true,
            name: response.data.data.name,
            score: response.data.data.score,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the name on input change
   * @param {e} value
   */
  function onNameChange(e) {
    setQuestionnaireData((current) => ({ ...current, name: e.target.value }));
  }

  /**
   * This method will update the score on input change
   * @param {e} e
   */
  function onScoreChange(e) {
    setQuestionnaireData((current) => ({ ...current, score: e.target.value }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(
        questionnaire ? `questionnaires/${questionnaire}` : "questionnaires"
      )
        .setLoadingGroup("questionnaire_page_save")
        .setMethod(questionnaire ? "PUT" : "POST")
        .setData(questionnaireData, ["fetched"])
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <QuestionnairePageView
      questionnaire={questionnaire}
      questionnaireData={questionnaireData}
      onNameChange={onNameChange}
      onScoreChange={onScoreChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default QuestionnairePage;
