import { useTheme } from "styled-components";
import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import Layout from "../../../../components/layout/Layout";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useTerminalContext } from "../../../../context/TerminalContext";
import { StyledAltButton } from "../../../../styles/ButtonStyles";
import { StyledInput } from "../../../../styles/InputStyles";
import { StyledFlexLayout } from "../../../../styles/LayoutStyles";
import { StartPageConfig } from "../start/StartPage";
import { StyledSignoutButton } from "./SignOutPageStyles";
import HiddenLoader from "../../../../components/loader/HiddenLoader/HiddenLoader";
import { VisitorTypePageConfig } from "../visitor_type/VisitorTypePage";

function SignOutPageView({
  setPageConfig,
  search,
  selectedVisitor,
  getVisitorSearchValue,
  onSearchChange,
}) {
  const theme = useTheme();
  const { terminal } = useTerminalContext();
  const { translate } = useLanguageContext();

  return (
    <Layout
      layoutData={terminal?.layout}
      content={
        <StyledFlexLayout style={{ flexDirection: "column" }}>
          <HiddenLoader groups={"sign_out_fetch"}>
            <StyledFlexLayout
              style={{
                flexDirection: "column",
                marginBottom: theme.spacing.large,
              }}
            >
              <InputWrapper
                required
                label={translate("eva.frontoffice.identification.visitor")}
                error={
                  !selectedVisitor &&
                  search.length > 0 &&
                  translate(
                    "eva.frontoffice.identification.visitor.not_found.unique"
                  )
                }
              >
                <StyledInput value={search} onChange={onSearchChange} />
              </InputWrapper>

              {selectedVisitor && (
                <StyledSignoutButton
                  onClick={() =>
                    setPageConfig(
                      VisitorTypePageConfig.setData({
                        visitorTypeId: selectedVisitor.visitor_type,
                        visitor: selectedVisitor,
                        signIn: false,
                      })
                    )
                  }
                >
                  {translate("eva.frontoffice.general.sign_out") + ": "}

                  <span>{getVisitorSearchValue(selectedVisitor)}</span>
                </StyledSignoutButton>
              )}
            </StyledFlexLayout>
          </HiddenLoader>

          <StyledAltButton onClick={() => setPageConfig(StartPageConfig)}>
            {translate("eva.frontoffice.general.back")}
          </StyledAltButton>
        </StyledFlexLayout>
      }
    />
  );
}

export default SignOutPageView;
