import {
  StyledDishDisplayImage,
  StyledDishDisplayPrice,
  StyledDishInfoDivider,
  StyledOrderPreviewContainer,
  StyledOrderTableNameTd,
  StyledOrderTableRow,
  StyledOrderTableTd,
} from "./OrderPageStyles";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledButton } from "../../../../styles/ButtonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../styles/LayoutStyles";
import Counter from "../../../../components/counter/Counter";
import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import { useTheme } from "styled-components";
import HiddenLoader from "../../../../components/loader/HiddenLoader/HiddenLoader";
import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../../../../styles/CardStyles";
import OverlayLoader from "../../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import Select from "../../../../components/select/main/Select";
import FileHelper from "../../../../helpers/FileHelper";

function OrderPageView({
  orderData,
  dishes,
  employees,
  showOrderOverview,
  setShowOrderOverview,
  getRealRemainingDishCount,
  getOrderDishCount,
  addDishToOrder,
  removeDishFromOrder,
  getOrderPrice,
  onEmployeeIdChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      {!showOrderOverview ? (
        <HiddenLoader groups="canteen_order_page_fetch">
          <StyledFlexLayout direction="column" gap={theme.spacing.large}>
            {dishes.map((dish, index) => (
              <StyledCard key={index}>
                <StyledCardHeader>
                  <StyledFlexLayout
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <StyledFlexLayout alignItems="center">
                      <h2>{dish.name}</h2>

                      <StyledDishDisplayPrice>
                        (&euro;{dish.price})
                      </StyledDishDisplayPrice>
                    </StyledFlexLayout>

                    <Counter
                      value={getOrderDishCount(dish.id)}
                      onMinusClick={() => removeDishFromOrder(dish.id)}
                      onPlusClick={() => addDishToOrder(dish.id)}
                    />
                  </StyledFlexLayout>
                </StyledCardHeader>

                <StyledCardBody>
                  <StyledFlexLayout>
                    <StyledDishDisplayImage
                      src={FileHelper.apiPath(dish.image)}
                      alt={translate("eva.main.validation.attributes.image")}
                    />

                    <StyledFlexLayoutItem>
                      {/* Dish description */}
                      {dish.description}

                      {dish.description ? <StyledDishInfoDivider /> : ""}

                      <StyledFlexLayout
                        style={{ flexDirection: "column", gap: 0 }}
                      >
                        {/* Dish allergens */}
                        {dish.allergens.length > 0 && (
                          <div>
                            <small>
                              <b>
                                {translate("eva.main.entities.allergens")}:{" "}
                              </b>
                              {dish.allergens.map(
                                (allergen, index) =>
                                  translate(
                                    `eva.main.allergens.${allergen.name}`
                                  ) +
                                  (index !== dish.allergens.length - 1
                                    ? ", "
                                    : "")
                              )}
                            </small>
                          </div>
                        )}

                        {/* Remaining */}
                        <small>
                          <b>{translate("eva.main.general.in_stock") + ": "}</b>
                          {getRealRemainingDishCount(dish.id)}
                        </small>

                        {/* Calories */}
                        <small>
                          <b>
                            {translate(
                              "eva.main.validation.attributes.calories"
                            ) + ": "}
                          </b>
                          {dish.calories}
                        </small>

                        {/* Health score */}
                        <small>
                          <b>
                            {translate(
                              "eva.main.validation.attributes.health_score"
                            ) + ": "}
                          </b>
                          {dish.health_score}
                        </small>

                        {/* Category*/}
                        <small>
                          <b>
                            {translate(
                              "eva.main.validation.attributes.category"
                            ) + ": "}
                          </b>
                          {dish.category}
                        </small>
                      </StyledFlexLayout>
                    </StyledFlexLayoutItem>
                  </StyledFlexLayout>
                </StyledCardBody>
              </StyledCard>
            ))}
          </StyledFlexLayout>
        </HiddenLoader>
      ) : (
        ""
      )}

      {!showOrderOverview && orderData.dishes.length > 0 ? (
        <StyledOrderPreviewContainer>
          {translate("eva.main.entities.order")}{" "}
          {translate("eva.main.general.overview").toLowerCase()}
          <StyledButton onClick={() => setShowOrderOverview(true)}>
            <FontAwesomeIcon icon={faCartShopping} /> ({orderData.dishes.length}
            )
          </StyledButton>
        </StyledOrderPreviewContainer>
      ) : (
        ""
      )}

      {/* Order overview card */}
      {showOrderOverview ? (
        <OverlayLoader groups="canteen_order_page_fetch">
          <FormContainer
            header={translate("eva.main.general.overview")}
            onCancel={onCancel}
            onSubmit={onSubmit}
            submitError={submitError}
            submitLoadingGroup={"canteen_order_page_save"}
          >
            {/* Employee id select */}
            <InputWrapper
              label={
                translate("eva.main.general.for") +
                " " +
                translate("eva.main.entities.employee").toLowerCase() +
                ":"
              }
              error={EVAApiHelper.getResponseError("employee_id", submitError)}
              margin={`0px 0px ${theme.spacing.large} 0px`}
            >
              <Select
                options={employees.map((employee) => {
                  return { value: employee.id, label: employee.name };
                })}
                nullable={true}
                value={orderData.employee_id}
                onChange={onEmployeeIdChange}
              />
            </InputWrapper>

            <InputWrapper
              error={EVAApiHelper.getResponseErrorArray(
                "dishes",
                submitError,
                (index) =>
                  dishes.find((dish) => dish.id === orderData.dishes[index])
                    .name
              )}
            >
              <table>
                {/* order dishes */}
                <tbody>
                  {dishes
                    .filter((dish) =>
                      orderData.dishes.find(
                        (orderDish) => orderDish === dish.id
                      )
                    )
                    .map((dish, index) => (
                      <StyledOrderTableRow key={index}>
                        {/* Order dish count */}
                        <StyledOrderTableTd>
                          {getOrderDishCount(dish.id)}
                        </StyledOrderTableTd>

                        {/* Order dish name */}
                        <StyledOrderTableNameTd>
                          {dish.name}
                        </StyledOrderTableNameTd>

                        {/* Order dish price */}
                        <StyledOrderTableTd>
                          &euro;
                          {parseFloat(
                            getOrderDishCount(dish.id) * dish.price
                          ).toFixed(2)}
                        </StyledOrderTableTd>

                        {/* Order dish counter */}
                        <StyledOrderTableTd>
                          <Counter
                            onMinusClick={() => removeDishFromOrder(dish.id)}
                            onPlusClick={() => addDishToOrder(dish.id)}
                          />
                        </StyledOrderTableTd>
                      </StyledOrderTableRow>
                    ))}
                </tbody>

                <tfoot>
                  <StyledOrderTableRow>
                    <StyledOrderTableTd></StyledOrderTableTd>

                    {/* Order total label */}
                    <StyledOrderTableTd>
                      <b>{translate("eva.main.general.total")}</b>
                    </StyledOrderTableTd>

                    {/* Order total price */}
                    <StyledOrderTableTd>
                      <b>&euro;{parseFloat(getOrderPrice()).toFixed(2)}</b>
                    </StyledOrderTableTd>
                  </StyledOrderTableRow>
                </tfoot>
              </table>
            </InputWrapper>
          </FormContainer>
        </OverlayLoader>
      ) : (
        ""
      )}
    </>
  );
}

export default OrderPageView;
