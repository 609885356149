import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../context/LanguageContext";
import FormContainer from "../../../../components/formContainer/FormContainer";
import { StyledInput } from "../../../../styles/InputStyles";
import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import OverlayLoader from "../../../../components/loader/OverlayLoader/OverlayLoader";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../styles/LayoutStyles";
import QuestionAnswersDataTable from "../../../../components/dataTable/tables/questionnaire/QuestionAnswersDataTable";
import EntityTranslationsContainer from "../../../../components/entityTranslationsContainer/EntityTranslationsContainer";
import ImageSelect from "../../../../components/select/custom/image/ImageSelect";

function QuestionPageView({
  question,
  questionData,
  images,
  translationsController,
  onImageChange,
  onNameChange,
  onOrderChange,
  onScoreChange,
  onGroupChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const { translate } = useLanguageContext();
  const theme = useTheme();

  return (
    <>
      <OverlayLoader groups="question_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"question_page_submit"}
        >
          <StyledFlexLayout>
            <ImageSelect
              images={images}
              value={questionData.image_id}
              onChange={onImageChange}
              style={{ width: "115px", height: "115px" }}
            />

            <StyledFlexLayoutItem>
              <StyledFlexLayout style={{ marginBottom: theme.spacing.normal }}>
                <StyledFlexLayoutItem>
                  <InputWrapper
                    label={translate("eva.main.validation.attributes.name")}
                    error={EVAApiHelper.getResponseError("name", submitError)}
                    required
                  >
                    <StyledInput
                      value={questionData.name}
                      onChange={onNameChange}
                      placeholder={translate(
                        "eva.main.validation.attributes.name"
                      )}
                    />
                  </InputWrapper>
                </StyledFlexLayoutItem>

                <InputWrapper
                  label={translate("eva.main.validation.attributes.order")}
                  error={EVAApiHelper.getResponseError("order", submitError)}
                  required
                >
                  <StyledInput
                    type="number"
                    value={questionData.order}
                    onChange={onOrderChange}
                    placeholder={translate(
                      "eva.main.validation.attributes.order"
                    )}
                  />
                </InputWrapper>

                <InputWrapper
                  label={translate("eva.main.validation.attributes.score")}
                  error={EVAApiHelper.getResponseError("score", submitError)}
                  required
                >
                  <StyledInput
                    type="number"
                    value={questionData.score}
                    onChange={onScoreChange}
                    placeholder={translate(
                      "eva.main.validation.attributes.score"
                    )}
                  />
                </InputWrapper>
              </StyledFlexLayout>

              <InputWrapper
                label={translate("eva.main.validation.attributes.group")}
                error={EVAApiHelper.getResponseError("group", submitError)}
              >
                <StyledInput
                  value={questionData.group}
                  onChange={onGroupChange}
                  placeholder={translate(
                    "eva.main.validation.attributes.group"
                  )}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>
          </StyledFlexLayout>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader
        groups="question_page_fetch"
        containerStyle={{ marginBlock: theme.spacing.large }}
      >
        <EntityTranslationsContainer controller={translationsController}>
          <InputWrapper
            label={translate("eva.main.validation.attributes.question")}
            error={translationsController.getTranslationError(
              "question",
              submitError
            )}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.question")}
              value={translationsController.getValue("question")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "question"
                )
              }
            />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>

      {questionData.fetched && <QuestionAnswersDataTable question={question} />}
    </>
  );
}

export default QuestionPageView;
