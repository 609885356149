import { useState } from "react";
import useCustomEffect from "../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import VisitorPageView from "./VisitorPageView";
import { useRouteContext } from "../../../context/RouteContext";
import { useParams } from "react-router-dom";

function VisitorPage() {
  const routeContext = useRouteContext();
  const { visitor } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [visitorTypes, setVisitorTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [visitorData, setVisitorData] = useState({
    visitor_type_id: null,
  });
  const visitorType = visitorTypes.find(
    (type) => type.id === visitorData.visitor_type_id
  );
  const callEvaApi = useEvaApi();

  /**
   * UseEffect for initializing the page
   */
  useCustomEffect(() => {
    fetchVisitorTypes();
    fetchEmployees();

    if (visitor) {
      fetchVisitorData();
    }
  });

  /**
   * This method will fetch the visitor types from the API.
   */
  async function fetchVisitorTypes() {
    await callEvaApi(
      new EvaApiCall("visitor_types")
        .setParams({
          with_visitor_type_sign_in_flow_data: true,
          with_flow_steps_data: true,
          with_flow_step_fields_data: true,
          with_flow_step_field_field_data: true,
          with_field_options_data: true,
        })
        .setLoadingGroup("visitor_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setVisitorTypes(response.data.data.records);

          if (!visitor) {
            setVisitorData({
              visitor_type_id: response.data.data.records[0].id,
            });
          }
        })
    );
  }

  /**
   * This method will fetch the employees from the API
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("visitor_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setEmployees(response.data.data.records);
        })
    );
  }

  /**
   * This method will fetch the visitor data from the API
   */
  async function fetchVisitorData() {
    await callEvaApi(
      new EvaApiCall(`visitors/${visitor}`)
        .setLoadingGroup("visitor_page_fetch")
        .setParams({
          with_visitor_fields_data: true,
        })
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          const newVisitorData = {
            visitor_type_id: response.data.data.visitor_type,
            employee_id: response.data.data.employee,
          };
          response.data.data.fields.forEach((fieldItem) => {
            newVisitorData[`field_${fieldItem.field}`] =
              fieldItem.value ?? fieldItem.field_option;
          });
          setVisitorData(newVisitorData);
        })
    );
  }

  /**
   * This method will handle the visitor type change event
   * @param {any} value
   */
  function onVisitorTypeChange(value) {
    setVisitorData({
      ...visitorData,
      visitor_type_id: value,
    });
  }

  /**
   * This method will handle the employee id change event
   * @param {any} value
   */
  function onEmployeeIdChange(value) {
    setVisitorData({
      ...visitorData,
      employee_id: value,
    });
  }

  /**
   * This method will handle the cancel event
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will handle the submit event
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(visitor ? `visitors/${visitor}` : "visitors")
        .setMethod(visitor ? "PUT" : "POST")
        .setLoadingGroup("visitor_page_submit")
        .setData(visitorData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <VisitorPageView
      visitorData={visitorData}
      setVisitorData={setVisitorData}
      visitorType={visitorType}
      visitorTypes={visitorTypes}
      onVisitorTypeChange={onVisitorTypeChange}
      employees={employees}
      onEmployeeIdChange={onEmployeeIdChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default VisitorPage;
