import { useMemo, useRef, useState } from "react";
import SelectView from "./SelectView";
import { useLanguageContext } from "../../../context/LanguageContext";
import useCustomEffect from "../../../hooks/useCustomEffect";

function Select({
  value,
  options = [],
  nullable = false,
  searchable = null,
  disabled = false,
  sort = true,
  onChange = (val) => {},
  as,
  hideCaret,
  valueVisual,
  placeholder,
  title,
  style,
}) {
  const { translate } = useLanguageContext();
  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState("");
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const optionsRef = useRef(null);
  const optionPositioningRef = useRef(null);

  // Sort the options by label
  const sortedOptions = useMemo(() => {
    return sort
      ? [...options].sort((a, b) => {
          return `${a.label}`.localeCompare(`${b.label}`);
        })
      : options;
  }, [options, sort]);

  // Add the null option
  if (nullable && sortedOptions[0]?.value !== null) {
    sortedOptions.unshift({
      value: null,
      label: translate("eva.main.general.select"),
    });
  }

  // Get the selected option
  const selectedOption = useMemo(() => {
    return (
      sortedOptions.find((option) => option.value === value) ??
      (nullable ? sortedOptions[0] : null)
    );
  }, [sortedOptions, value, nullable]);

  // Filter the options by search
  const filteredOptions = useMemo(() => {
    return search
      ? sortedOptions.filter((option) => {
          return `${option.label}`.toLowerCase().includes(search.toLowerCase());
        })
      : sortedOptions;
  }, [search, sortedOptions]);

  //Determine the default searchable value
  if (searchable === null) {
    searchable =
      sortedOptions.length >= 10 &&
      selectRef?.current !== null &&
      selectRef.current.getBoundingClientRect().width > 200;
  }

  // Create the options portal
  const optionsPortal = document.getElementById("select-options-portal");
  if (!optionsPortal) {
    const portal = document.createElement("div");
    portal.id = "select-options-portal";
    document.body.appendChild(portal);
  }

  /**
   * UseEffect for managing focus changes
   */
  useCustomEffect(() => {
    if (focused) {
      inputRef.current.focus();
    } else {
      setSearch("");
      inputRef.current.blur();
    }
  }, [focused]);

  /**
   * UseEffect for managing the options repositioning on window events
   */
  useCustomEffect(() => {
    window.addEventListener("wheel", repositionOptions);
    return () => {
      window.removeEventListener("wheel", repositionOptions);
    };
  });

  /**
   * This method will handle the change event
   * @param {any} newValue
   */
  function onValueChange(newValue) {
    if (newValue !== value) {
      onChange(newValue);
    }
  }

  /**
   * This method will handle focus change event
   */
  function onFocusChange(value) {
    if (!value) {
      setTimeout(() => {
        setFocused(false);
      }, 100);
    } else {
      setFocused(true);
    }
  }

  /**
   * This method will handle the search change event
   * @param {event} e
   */
  function onSearchChange(e) {
    if (!searchable) return;
    setSearch(e.target.value);
  }

  /**
   * This method will handle the search confirm event
   * @param {event} e
   */
  function onSearchConfirm(e) {
    if (e.key === "Enter" && searchable) {
      onValueChange(filteredOptions[0]?.value);
      setFocused(false);
    }
  }

  /**
   * This method will reposition the options
   */
  function repositionOptions() {
    const select = selectRef.current;
    const options = optionsRef.current;
    if (!select || !options || !focused) return;

    const selectRect = select.getBoundingClientRect();

    //Prepare the options for calculation
    options.style.opacity = 0;
    options.style.left = `${selectRect.left}px`;
    options.style.top = `${selectRect.top + selectRect.height + 2}px`;
    options.style.minWidth = `${selectRect.width}px`;

    //Reposition after the next render
    cancelAnimationFrame(optionPositioningRef.current);
    optionPositioningRef.current = requestAnimationFrame(() => {
      const optionsRect = options.getBoundingClientRect();

      options.style.opacity = 1;

      //Move the options to the left if it's out of the screen on the right
      if (optionsRect.right > window.innerWidth) {
        options.style.left = `${
          optionsRect.left - (optionsRect.right - window.innerWidth) - 20
        }px`;
      }

      //Move the options to above the select if it's out of the screen on the bottom
      if (optionsRect.bottom > window.innerHeight) {
        options.style.top = `${selectRect.top - optionsRect.height - 2}px`;
      }
    });
  }

  //resposition the options
  repositionOptions();

  return (
    <SelectView
      value={selectedOption}
      search={search}
      searchable={searchable}
      disabled={disabled}
      focused={focused}
      options={filteredOptions}
      inputRef={inputRef}
      selectRef={selectRef}
      optionsRef={optionsRef}
      optionsPortal={optionsPortal}
      onSearchChange={onSearchChange}
      onFocusChange={onFocusChange}
      onValueChange={onValueChange}
      onSearchConfirm={onSearchConfirm}
      as={as}
      hideCaret={hideCaret}
      valueVisual={valueVisual}
      placeholder={placeholder}
      title={title}
      style={style}
    />
  );
}

export default Select;
