import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../../context/LanguageContext";
import FileHelper from "../../../../helpers/FileHelper";
import Select from "../../main/Select";
import {
  StyledImage,
  StyledImageSelect,
  StyledName,
} from "./ImageSelectStyles";
import { faImage } from "@fortawesome/free-solid-svg-icons";

function ImageSelectView({ images, value, onChange, style }) {
  const { translate, translateEntity } = useLanguageContext();
  const path = value ? translateEntity(value, "path") : null;

  return (
    <Select
      value={value}
      searchable={false}
      valueVisual={() => (
        <StyledImageSelect style={style}>
          {value && path ? (
            <StyledImage src={FileHelper.apiPath(path)} alt={value.name} />
          ) : (
            <FontAwesomeIcon
              icon={faImage}
              style={{ width: "50%", height: "50%" }}
            />
          )}

          <StyledName>
            {value ? value.name : translate("eva.main.general.select")}
          </StyledName>
        </StyledImageSelect>
      )}
      options={images.map((image) => ({ value: image.id, label: image.name }))}
      onChange={onChange}
      nullable={true}
      hideCaret={true}
      style={{
        ...style,
        padding: "0",
        overflow: "hidden",
      }}
    />
  );
}

export default ImageSelectView;
