import { useState } from "react";
import { useLanguageContext } from "../../../context/LanguageContext";
import { faCheck, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useParameterContext } from "../../../context/ParameterContext";
import { StyledInput } from "../../../styles/InputStyles";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import RowActionConfig from "../main/config/rowAction/RowActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";
import MultiSelect from "../../select/main/multi/MultiSelect";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useAuthContext } from "../../../context/AuthContext";

function ParametersDataTable() {
  const callEvaApi = useEvaApi();
  const authContext = useAuthContext();
  const parameterContext = useParameterContext();
  const { translate } = useLanguageContext();
  const [editRow, setEditRow] = useState(null);
  const [fields, setFields] = useState([]);

  //Create the datatable config
  const config = new DataTableConfig("parameters");

  //Set the datatable fetch info
  config.setFetchRequest("parameters");

  //Set the datatable order by
  config.setOrderBy("key.translation");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("key.translation")
      .setData((row) => translate(`eva.main.parameters.${row.key}`))
      .setLockActive(true),
    useBasicColumnConfig("value")
      .setData(getValueColumnData)
      .setLockActive(true)
      .setSearchable(false)
      .setOrderable(false),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    new RowActionConfig()
      .setIcon(faPen)
      .setCallback(handleEdit)
      .setHidden((row) => row.id === editRow?.id)
      .setTitle(
        translate("eva.main.entities.states.edit", [
          { key: "entities", value: translate("eva.main.entities.parameter") },
        ])
      ),
    new RowActionConfig()
      .setIcon(faXmark)
      .setCallback(handleCancel)
      .setHidden((row) => row.id !== editRow?.id)
      .setTitle(translate("eva.main.general.cancel")),
    new RowActionConfig()
      .setIcon((row) => faCheck)
      .setCallback(handleConfirm)
      .setHidden((row) => row.id !== editRow?.id)
      .setTitle(translate("eva.main.general.save")),
  ]);

  //Set the overall loading groups for the datatable row actions
  config.setRowActionsLoadingGroups(
    (row) => `${config.getDataTableKey()}_update_${row.id}`
  );

  //Create the datatable object
  const dataTable = useDataTable(config);

  /**
   * This effect will fetch the fields from the API
   */
  useCustomEffect(() => {
    fetchFields();
  });

  /**
   * This method will fetch the fields from the API
   */
  async function fetchFields() {
    if (authContext.auth?.user?.company?.type === "superadmin") {
      return;
    }

    await callEvaApi(
      new EvaApiCall("fields")
        .setLoadingGroup(config.getFetchKey())
        .setOnSuccess((response) => {
          setFields(response.data.data.records);
        })
    );
  }

  /**
   * This method will return the data representation of the value column
   * @param {object} row
   * @returns {string|any}
   */
  function getValueColumnData(row) {
    if (row.id !== editRow?.id) {
      return getValueColumnViewData(row);
    } else {
      return getValueColumnEditData(row);
    }
  }

  /**
   * This method will return the data representation of the value column in view mode
   * @param {object} row
   * @returns {any}
   */
  function getValueColumnViewData(row) {
    switch (row.type) {
      case "fields":
        return JSON.stringify(
          row.fields.map((field) => fields.find((f) => f.id === field)?.key)
        );
      default:
        return row.value;
    }
  }

  /**
   * This method will return the data representation of the value column in edit mode
   * @param {object} row
   * @returns {any}
   */
  function getValueColumnEditData(row) {
    switch (row.type) {
      case "fields":
        return (
          <MultiSelect
            value={editRow.fields}
            options={fields.map((field) => ({
              value: field.id,
              label: field.key,
            }))}
            onChange={(value) => setEditRow({ ...editRow, fields: value })}
          />
        );

      default:
        return (
          <StyledInput
            type={row.type}
            value={editRow.value}
            onChange={(e) => setEditRow({ ...editRow, value: e.target.value })}
            width="100%"
          />
        );
    }
  }

  /**
   * This method will start the edit state of the given row
   * @param {object} row
   */
  function handleEdit(row) {
    setEditRow(row);
  }

  /**
   * This method will cancel the update of the given row
   */
  function handleCancel() {
    setEditRow(null);
  }

  /**
   * This method will send a request to the API for updating the given row
   * @param {object} row
   */
  async function handleConfirm(row) {
    if (editRow.value === row.value && editRow.fields === row.fields) {
      setEditRow(null);
      return;
    }

    await callEvaApi(
      new EvaApiCall(`parameters/${row.id}`)
        .setMethod("PUT")
        .setData({ value: editRow.value, fields: editRow.fields })
        .setLoadingGroup(`${config.getDataTableKey()}_update_${row.id}`)
        .setOnSuccess((response) => {
          row.value = response.data.data.value;
          setEditRow(null);
          parameterContext.fetchParameters();
        })
    );
  }

  return <DataTable controller={dataTable} />;
}

export default ParametersDataTable;
