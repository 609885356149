import { useTheme } from "styled-components";
import MailSettingsDataTable from "../../../components/dataTable/tables/MailSettingsDataTable";
import { useLanguageContext } from "../../../context/LanguageContext";
import FormContainer from "../../../components/formContainer/FormContainer";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../styles/LayoutStyles";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../../styles/InputStyles";
import { StyledButton } from "../../../styles/ButtonStyles";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HiddenLoader from "../../../components/loader/HiddenLoader/HiddenLoader";

function MailSettingsPageView({ receiver, onReceiverChange, onSend }) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledFlexLayout style={{ flexDirection: 'column', gap: theme.spacing.large }}>
      <MailSettingsDataTable />

      <FormContainer header={translate("eva.main.general.test")}>
        <StyledFlexLayout>
          <StyledFlexLayoutItem>
            <InputWrapper>
              <StyledInput
                type="email"
                value={receiver}
                onChange={onReceiverChange}
                placeholder={translate(
                  "eva.main.validation.attributes.receiver"
                )}
                style={{ marginRight: theme.spacing.normal }}
              />
            </InputWrapper>
          </StyledFlexLayoutItem>

          <HiddenLoader groups="mail_settings_test" icon={faSpinner}>
            <InputWrapper style={{ minWidth: "200px" }}>
              <StyledButton onClick={onSend}>
                {translate("eva.main.general.send")}
              </StyledButton>
            </InputWrapper>
          </HiddenLoader>
        </StyledFlexLayout>
      </FormContainer>
    </StyledFlexLayout>
  );
}

export default MailSettingsPageView;
