import FlowPageQuestionnaireFormView from "./FlowPageQuestionnaireFormView";

function FlowPageQuestionnaireForm({
  flowData,
  setFlowData,
  questionnaires,
  messageTemplates,
  employees,
  submitError,
}) {
  /**
   * This method will handle the questionnaire change event.
   * @param {any} value
   */
  function onQuestionnaireChange(value) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].questionnaire_id = value;

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the on fail change event.
   * @param {any} value
   */
  function onFailChange(value) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].on_fail = value;
      newSteps[prevFlowData.selectedStep].on_fail_step = null;

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the on fail step change event.
   * @param {any} value
   */
  function onFailStepChange(value) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].on_fail_step = value;

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  return (
    <FlowPageQuestionnaireFormView
      flowData={flowData}
      setFlowData={setFlowData}
      questionnaires={questionnaires}
      messageTemplates={messageTemplates}
      employees={employees}
      onQuestionnaireChange={onQuestionnaireChange}
      onFailChange={onFailChange}
      onFailStepChange={onFailStepChange}
      submitError={submitError}
    />
  );
}

export default FlowPageQuestionnaireForm;
