import { useState } from "react";
import QuestionnaireStepView from "./QuestionnaireStepView";
import useCustomEffect from "../../../../../../hooks/useCustomEffect";

function QuestionnaireStep({
  step,
  visitorData,
  setVisitorData,
  setPageConfig,
  nextStep,
  previousStep,
  errors,
}) {
  const [questionnaire, setQuestionnaire] = useState(getQuestionnaire());

  /**
   * This effect will update the questionnaire object when the step changes
   */
  useCustomEffect(() => {
    setQuestionnaire(getQuestionnaire());
  }, [step.questionnaire]);

  /**
   * This method will return the new questionnaire object with random questions from each group
   * @returns {Object}
   */
  function getQuestionnaire() {
    // Clone the questionnaire object and get the groups
    const questionnaire = { ...step.questionnaire };
    const groups = getQuestionnaireGroups(questionnaire);

    //Remove the groups from the questionnaire
    questionnaire.questions = questionnaire.questions.filter(
      (question) => !question.group
    );

    //Add a question from each group to the questionnaire
    for (const group in groups) {
      const groupQuestion = getGroupQuestion(questionnaire, groups[group]);
      questionnaire.questions.push(groupQuestion);
    }

    //re order the questions because the group questions are added at the end
    questionnaire.questions = questionnaire.questions.sort(
      (a, b) => a.order - b.order
    );

    // Return the new questionnaire
    return questionnaire;
  }

  /**
   * This method will return the grouped questions in the questionnaire
   * @returns {Object}
   */
  function getQuestionnaireGroups(questionnaire) {
    const groups = {};

    for (const question of questionnaire.questions) {
      if (question.group) {
        if (!groups[question.group]) {
          groups[question.group] = [];
        }
        groups[question.group].push({ ...question });
      }
    }

    return groups;
  }

  /**
   * This method will return a question from the group
   * @param {object} group
   * @returns {object}
   */
  function getGroupQuestion(questionnaire, group) {
    //If the user has already answered a question in the group, return it again
    for (const question of group) {
      if (
        visitorData[`questionnaire_${questionnaire.id}_question_${question.id}`]
      ) {
        return question;
      }
    }

    //Otherwise, return a random question from the group
    const randomIndex = Math.floor(Math.random() * group.length);
    return group[randomIndex];
  }

  /**
   * This method will handle the question change event
   * @param {object} question
   * @param {any} value
   */
  function onQuestionChange(question, value) {
    setVisitorData((prevData) => ({
      ...prevData,
      [`questionnaire_${questionnaire.id}_question_${question.id}`]: value,
    }));
  }

  return (
    <QuestionnaireStepView
      step={step}
      questionnaire={questionnaire}
      visitorData={visitorData}
      setPageConfig={setPageConfig}
      nextStep={nextStep}
      previousStep={previousStep}
      errors={errors}
      onQuestionChange={onQuestionChange}
    />
  );
}

export default QuestionnaireStep;
