import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../../context/LanguageContext";
import IconSelect from "../../main/icon/IconSelect";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../modal/Modal";

function HelpSelectView({ helpText, helpTexts, onChange, disabled }) {
  const { translate, translateEntity } = useLanguageContext();

  return (
    helpTexts.length > 0 && (
      <>
        <IconSelect
          options={helpTexts.map((helpText) => ({
            value: helpText.id,
            label: translateEntity(helpText, "title"),
          }))}
          onChange={onChange}
          disabled={disabled}
          title={translate("eva.main.general.help")}
          icon={(value) => <FontAwesomeIcon icon={faQuestion} />}
        />

        {helpText && (
          <Modal
            title={translateEntity(helpText, "title")}
            onClose={() => onChange(null)}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: translateEntity(helpText, "description"),
              }}
            />
          </Modal>
        )}
      </>
    )
  );
}

export default HelpSelectView;
