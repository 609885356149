import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledAltIconButton } from "../../../../styles/ButtonStyles";
import Select from "../Select";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

function IconSelect({
  value,
  options,
  onChange,
  icon = (val) => null,
  disabled,
  title,
}) {
  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      title={title}
      disabled={disabled}
      hideCaret={true}
      as={StyledAltIconButton}
      valueVisual={(val) => icon(val) ?? <FontAwesomeIcon icon={faQuestion} />}
    />
  );
}

export default IconSelect;
