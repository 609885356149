import { useState } from "react";
import MailSettingsPageView from "./MailSettingsPageView";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function MailSettingsPage() {
  const [receiver, setReceiver] = useState("");
  const callEvaApi = useEvaApi();

  /**
   * This method will send a request to the API for a mail send test
   */
  async function onSend() {
    // Send the mail test request
    await callEvaApi(
      new EvaApiCall(`mail_settings`)
        .setMethod("POST")
        .setData({ receiver: receiver })
        .setLoadingGroup("mail_settings_test")
    );
  }

  // Function to change the receiver
  function onReceiverChange(e) {
    setReceiver(e.target.value);
  }

  return (
    <MailSettingsPageView
      receiver={receiver}
      onReceiverChange={onReceiverChange}
      onSend={onSend}
    />
  );
}

export default MailSettingsPage;
