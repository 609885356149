import styled from "styled-components";
import { MultiStyle } from "../../../context/ThemeContext";

export const StyledSelectWrapper = styled.div`
  ${(props) =>
    MultiStyle.new(props, "background-color")
      .setBack(props.theme.colors.primary.lightest)
      .setFront(props.theme["settings.input.color.primary.background"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "color")
      .setBack(props.theme.colors.on.primary)
      .setFront(props.theme["settings.input.color.primary.text"] ?? "inherit")
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border-color")
      .setBack("transparent")
      .setFront(props.theme["settings.input.color.primary.border"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border-radius")
      .setBack(props.theme.radius.default)
      .setFront(`${props.theme["settings.input.border.radius"]}px`)
      .get()}
  ${(props) =>
    MultiStyle.new(props, "padding")
      .setBack(props.theme.spacing.normal)
      .setFront(`${props.theme["settings.input.padding"]}px`)
      .get()}
  ${(props) => MultiStyle.new(props, "width").setFront("100%").get()}

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
  border-width: 1px;
  border-style: solid;
  max-width: 100%;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};

  &:hover {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setFront(props.theme["settings.input.color.background.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setFront(props.theme["settings.input.color.text.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "border-color")
        .setBack(props.theme.colors.accent.dark)
        .setFront(props.theme["settings.input.color.primary.border.focus"])
        .get()}
  }

  &:focus-within {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setFront(props.theme["settings.input.color.background.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setFront(props.theme["settings.input.color.text.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "border-color")
        .setBack(props.theme.colors.accent.normal)
        .setFront(props.theme["settings.input.color.primary.border.focus"])
        .get()}
  }
`;

export const StyledSelectValueWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

export const StyledSelectValue = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: ${(props) => (props.hide === "true" ? "20px" : "initial")};
  opacity: ${(props) => (props.hide === "true" ? 0 : 1)};
`;

export const StyledSelectInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: transparent;
  pointer-events: none;
  opacity: ${(props) => (props.hide === "true" ? 0 : 1)};
`;

export const StyledSelectOptions = styled.div`
  ${(props) =>
    MultiStyle.new(props, "background-color")
      .setBack(props.theme.colors.primary.lightest)
      .setFront(props.theme["settings.input.color.primary.background"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border-radius")
      .setBack(props.theme.radius.default)
      .setFront(`${props.theme["settings.input.border.radius"]}px`)
      .get()}
  ${(props) =>
    MultiStyle.new(props, "color")
      .setBack(props.theme.colors.on.primary)
      .setFront(props.theme["settings.input.color.primary.text"] ?? "inherit")
      .get()}

  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: fit-content;
  max-width: calc(100vw - 40px);
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.hide === "true" ? "none" : "initial")};
`;

export const StyledSelectOption = styled.div`
  ${(props) =>
    MultiStyle.new(props, "padding")
      .setBack(props.theme.spacing.normal)
      .setFront(`${props.theme["settings.input.padding"]}px`)
      .get()}

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${(props) =>
    props.active === "true" ? "rgba(0, 0, 0, 0.1)" : "transparent"};
  text-align: left;
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
