import FieldsStepView from "./FieldsStepView";

function FieldsStep({
  step,
  errors,
  visitorData,
  setVisitorData,
  setPageConfig,
  nextStep,
  previousStep,
}) {
  return (
    <FieldsStepView
      step={step}
      errors={errors}
      visitorData={visitorData}
      setVisitorData={setVisitorData}
      setPageConfig={setPageConfig}
      nextStep={nextStep}
      previousStep={previousStep}
    />
  );
}

export default FieldsStep;
