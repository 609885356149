import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledSelectInput,
  StyledSelectOption,
  StyledSelectOptions,
  StyledSelectValue,
  StyledSelectValueWrapper,
  StyledSelectWrapper,
} from "./SelectStyles";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../../context/LanguageContext";
import ReactDOM from "react-dom";

function SelectView({
  value,
  search,
  searchable,
  disabled,
  focused,
  options,
  inputRef,
  selectRef,
  optionsRef,
  optionsPortal,
  onSearchChange,
  onFocusChange,
  onValueChange,
  onSearchConfirm,
  as: Element = StyledSelectWrapper,
  hideCaret = false,
  valueVisual = (option) => option?.label,
  placeholder,
  title,
  style,
}) {
  const { translate } = useLanguageContext();

  return (
    <Element
      ref={selectRef}
      disabled={disabled}
      title={title}
      style={style}
      onClick={() => onFocusChange(true)}
    >
      <StyledSelectValueWrapper>
        <StyledSelectValue hide={`${focused && searchable}`}>
          {valueVisual(value) ??
            placeholder ??
            translate("eva.main.general.select")}
        </StyledSelectValue>

        <StyledSelectInput
          ref={inputRef}
          value={search}
          disabled={disabled}
          onChange={onSearchChange}
          onKeyDown={onSearchConfirm}
          onFocus={() => onFocusChange(true)}
          onBlur={() => onFocusChange(false)}
          hide={`${!focused || !searchable}`}
        />
      </StyledSelectValueWrapper>

      {!hideCaret && <FontAwesomeIcon icon={faCaretDown} size="sm" />}

      {optionsPortal &&
        ReactDOM.createPortal(
          <StyledSelectOptions ref={optionsRef} hide={`${!focused}`}>
            {focused &&
              options.map((option) => (
                <StyledSelectOption
                  key={option.value}
                  onClick={() => onValueChange(option.value)}
                  active={`${value?.value === option.value}`}
                  disabled={option.disabled ?? false}
                >
                  {option.label}
                </StyledSelectOption>
              ))}

            {focused && options.length < 1 && (
              <StyledSelectOption>
                {translate("eva.main.general.no_results")}
              </StyledSelectOption>
            )}
          </StyledSelectOptions>,
          optionsPortal
        )}
    </Element>
  );
}

export default SelectView;
