import { useState } from "react";
import { useTerminalContext } from "../../../../context/TerminalContext";
import FrontOfficePageConfig from "../../FrontOfficePageConfig";
import VisitorTypePageView from "./VisitorTypePageView";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import { StartPageConfig } from "../start/StartPage";

function VisitorTypePage({ pageConfig, setPageConfig }) {
  const callEvaApi = useEvaApi();
  const { terminal } = useTerminalContext();
  const visitorType = terminal?.visitor_types?.find(
    (visType) =>
      parseInt(visType.id) === parseInt(pageConfig.data?.visitorTypeId)
  );
  const signIn =
    pageConfig.data?.signIn !== undefined ? pageConfig.data.signIn : true;
  const layout = visitorType?.layout || terminal?.layout;
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [visitorData, setVisitorData] = useState(getInitialVisitorData());

  /**
   * UseEffect for processing the current step or fetching the first step if it's not set
   */
  useCustomEffect(() => {
    nextStep();
  });

  /**
   * This method will return the initial visitor data
   * @returns {object}
   */
  function getInitialVisitorData() {
    const initData = {};

    // Fill in the visitor data if it's already set
    const visitor = pageConfig.data?.visitor;
    if (visitor) {
      initData.employee_id = visitor.employee;
      visitor.fields.forEach((visField) => {
        initData[`field_${visField.field}`] =
          visField.value ?? visField.field_option;
      });
    }

    return initData;
  }

  /**
   * This method will try to get the next step
   */
  async function nextStep() {
    await callEvaApi(
      new EvaApiCall(
        `flows/${
          signIn ? visitorType.sign_in_flow : visitorType.sign_out_flow
        }/steps/next${step ? `/${step?.id}` : ""}`
      )
        .setLoadingGroup("next_step")
        .setMethod("POST")
        .setParams(getFetchParams())
        .setErrorState(setErrors)
        .setData({
          step_id: step?.id,
          ...visitorData,
        })
        .setAlertError(true)
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          const newStep = response.data.data;
          if (newStep) {
            setStep(newStep);
          } else {
            onFlowComplete();
          }
        })
    );
  }

  /**
   * This method will try to go back to the previous step
   * @param {int|null} stepId
   */
  async function previousStep() {
    await callEvaApi(
      new EvaApiCall(
        `flows/${
          signIn ? visitorType.sign_in_flow : visitorType.sign_out_flow
        }/steps/previous/${step?.id}`
      )
        .setLoadingGroup("previous_step")
        .setMethod("POST")
        .setParams(getFetchParams())
        .setData({ step_id: step?.id })
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          const newStep = response.data.data;
          setStep(newStep);
        })
    );
  }

  /**
   * This method will handle the flow completion event
   */
  async function onFlowComplete() {
    const visitorId = pageConfig.data?.visitor?.id;
    await callEvaApi(
      new EvaApiCall(
        `visitors/${signIn ? "sign_in" : "sign_out"}${
          visitorId ? `/${visitorId}` : ""
        }`
      )
        .setLoadingGroup("complete_flow")
        .setMethod("POST")
        .setData({
          visitor_type_id: visitorType.id,
          ...visitorData,
        })
        .setAlertSuccess(true)
        .setOnSuccess(() => {
          setPageConfig(StartPageConfig);
        })
    );
  }

  /**
   * This method will return the fetch params for the API
   * @returns {object}
   */
  function getFetchParams() {
    return {
      with_flow_step_fields_data: true,
      with_flow_step_field_field_data: true,
      with_flow_step_questionnaire_data: true,
      with_flow_step_file_data: true,
      with_field_options_data: true,
      with_questionnaire_questions_data: true,
      with_question_answers_data: true,
      with_question_image_data: true,
    };
  }

  return (
    <VisitorTypePageView
      layout={layout}
      step={step}
      errors={errors}
      visitorData={visitorData}
      setVisitorData={setVisitorData}
      employees={employees}
      setEmployees={setEmployees}
      nextStep={nextStep}
      previousStep={previousStep}
      setPageConfig={setPageConfig}
    />
  );
}

export const VisitorTypePageConfig = new FrontOfficePageConfig(
  VisitorTypePage,
  "visitor_type"
);

export default VisitorTypePage;
