import useCustomEffect from "../../../../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../../../../hooks/useEvaApi";
import EmployeeStepView from "./EmployeeStepView";

function EmployeeStep({
  step,
  errors,
  visitorData,
  setVisitorData,
  setPageConfig,
  nextStep,
  previousStep,
  employees,
  setEmployees,
}) {
  const callEvaApi = useEvaApi();

  /**
   * UseEffect for automatically fetching the employees if it's not set
   */
  useCustomEffect(() => {
    if (employees === null) {
      fetchEmployees();
    }
  }, [employees]);

  /**
   * This method will fetch the employees from the API
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("process_step")
        .setOnSuccess((response) => {
          setEmployees(response.data.data.records);
        })
    );
  }

  /**
   * This method will handle the employee id change event
   * @param {any} value
   */
  function onEmployeeIdChange(value) {
    setVisitorData((prev) => ({ ...prev, employee_id: value }));
  }

  return (
    <EmployeeStepView
      step={step}
      errors={errors}
      visitorData={visitorData}
      setPageConfig={setPageConfig}
      nextStep={nextStep}
      previousStep={previousStep}
      employees={employees}
      onEmployeeIdChange={onEmployeeIdChange}
    />
  );
}

export default EmployeeStep;
