import { useState } from "react";
import ScreensaverView from "./ScreensaverView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRef } from "react";
import { useParameterContext } from "../../../context/ParameterContext";

function Screensaver({ screensavers = [], onActivation, onDeactivation }) {
  const parameterContext = useParameterContext();
  const switchTimer = useRef(null);
  const activateTimer = useRef(null);
  const [data, setData] = useState({
    currentIndex: 0,
    showPrevious: false,
    disabled: false,
  });

  // Set the current screensaver
  const currentScreensaver =
    screensavers.length > data.currentIndex
      ? screensavers[data.currentIndex]
      : null;

  // Set the previous screensaver
  const previousScreensaver =
    screensavers.length > getPreviousIndex() &&
    data.showPrevious &&
    getPreviousIndex() !== data.currentIndex
      ? screensavers[getPreviousIndex()]
      : null;

  /**
   * UseEffect for initializations and cleanup
   */
  useCustomEffect(() => {
    window.addEventListener("click", resetActivateTimer);

    return () => {
      clearInterval(switchTimer.current);
      clearTimeout(activateTimer.current);
      window.removeEventListener("click", resetActivateTimer);
    };
  });

  /**
   * UseEffect for activating and deactivating the screensaver
   */
  useCustomEffect(() => {
    if (data.disabled || screensavers.length === 0) {
      clearInterval(switchTimer.current);
      startActivateTimer();

      if (onDeactivation) {
        onDeactivation();
      }
    } else {
      startSwitchTimer();

      if (onActivation) {
        onActivation();
      }
    }
  }, [data.disabled, screensavers]);

  /**
   * This method will activate the switch interval for the screensavers
   */
  function startSwitchTimer() {
    clearInterval(switchTimer.current);

    switchTimer.current = setInterval(() => {
      setData((prev) => ({
        ...prev,
        showPrevious: true,
        currentIndex:
          prev.currentIndex + 1 >= screensavers.length
            ? 0
            : prev.currentIndex + 1,
      }));
    }, getSwitchTimer());
  }

  /**
   * This method will activate the timer for the screensavers
   */
  function startActivateTimer() {
    clearInterval(activateTimer.current);

    activateTimer.current = setTimeout(() => {
      setData((prev) => ({
        ...prev,
        disabled: false,
        currentIndex: 0,
        showPrevious: false,
      }));
    }, getActivateTimer());
  }

  /**
   * This method will reset the activate timer for the screensavers
   */
  function resetActivateTimer() {
    if (activateTimer.current) {
      startActivateTimer();
    }
  }

  /**
   * This method will return the activate timer for the screensavers
   * @returns {number}
   */
  function getActivateTimer() {
    return (
      (parameterContext.getParameter("screensaver.timer.activate")?.value ??
        30) * 1000
    );
  }

  /**
   * This method will return the switch timer for the screensavers
   * @returns {number}
   */
  function getSwitchTimer() {
    return (
      (parameterContext.getParameter("screensaver.timer.switch")?.value ?? 10) *
      1000
    );
  }

  /**
   * This method will return the previous screensaver index
   * @returns {number}
   */
  function getPreviousIndex() {
    return data.currentIndex - 1 < 0
      ? screensavers.length - 1
      : data.currentIndex - 1;
  }

  /**
   * This method will return the fade time for the screensavers
   * @returns {number}
   */
  function getFadeTime() {
    return Math.min(1500, getSwitchTimer());
  }

  /**
   * This method will be called when the screensaver is clicked
   */
  function onScreensaverClick() {
    setData((prev) => ({
      ...prev,
      disabled: true,
    }));
  }

  return (
    <ScreensaverView
      data={data}
      fadeTime={getFadeTime()}
      currentScreensaver={currentScreensaver}
      previousScreensaver={previousScreensaver}
      onScreensaverClick={onScreensaverClick}
    />
  );
}

export default Screensaver;
