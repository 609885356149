import { faCheck, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import DataTable, { useDataTable } from "../main/DataTable";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import RowActionConfig from "../main/config/rowAction/RowActionConfig";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useState } from "react";
import { StyledInput } from "../../../styles/InputStyles";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function MailSettingsDataTable() {
  const callEvaApi = useEvaApi();
  const { translate } = useLanguageContext();
  const [editRow, setEditRow] = useState(null);

  //Create the datatable config
  const config = new DataTableConfig("mail_settings");

  //Set the datatable fetch info
  config.setFetchRequest("mail_settings");

  //Set the datatable columns
  config.setColumns([
    useBasicColumnConfig("key.translation")
      .setData((row) => translate(`eva.main.mail_settings.${row.key}`))
      .setLockActive(true),
    useBasicColumnConfig("value")
      .setData(getValueColumnData)
      .setLockActive(true),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    new RowActionConfig()
      .setIcon(faPen)
      .setCallback(handleEdit)
      .setHidden((row) => row.id === editRow?.id)
      .setTitle(
        translate("eva.main.entities.states.edit", [
          {
            key: "entities",
            value: translate("eva.main.entities.mail_setting"),
          },
        ])
      ),
    new RowActionConfig()
      .setIcon(faXmark)
      .setCallback(handleCancel)
      .setHidden((row) => row.id !== editRow?.id)
      .setTitle(translate("eva.main.general.cancel")),
    new RowActionConfig()
      .setIcon((row) => faCheck)
      .setCallback(handleConfirm)
      .setHidden((row) => row.id !== editRow?.id)
      .setTitle(translate("eva.main.general.save")),
  ]);

  //Set the overall loading groups for the datatable row actions
  config.setRowActionsLoadingGroups(
    (row) => `${config.getDataTableKey()}_update_${row.id}`
  );

  //Create the datatable object
  const dataTable = useDataTable(config);

  /**
   * This method will return the data representation of the value column
   * @param {object} row
   * @returns {string|any}
   */
  function getValueColumnData(row) {
    if (row.id !== editRow?.id) {
      //Password input
      if (row.type === "password") {
        return "*********";
      }

      return row.value;
    } else {
      //Default input
      return (
        <StyledInput
          type={row.type}
          value={editRow.value ?? ""}
          onChange={(e) => setEditRow({ ...editRow, value: e.target.value })}
          width="100%"
        />
      );
    }
  }

  /**
   * This method will start the edit state of the given row
   * @param {object} row
   */
  function handleEdit(row) {
    setEditRow(row);
  }

  /**
   * This method will cancel the update of the given row
   */
  function handleCancel() {
    setEditRow(null);
  }

  /**
   * This method will send a request to the API for updating the given row
   * @param {object} row
   */
  async function handleConfirm(row) {
    if (editRow.value === row.value) {
      setEditRow(null);
      return;
    }

    await callEvaApi(
      new EvaApiCall(`mail_settings/${row.id}`)
        .setMethod("PUT")
        .setData({ value: editRow.value })
        .setLoadingGroup(`${config.getDataTableKey()}_update_${row.id}`)
        .setOnSuccess((response) => {
          row.id = response.data.data.id;
          row.value = response.data.data.value;
          setEditRow(null);
        })
    );
  }

  return <DataTable controller={dataTable} />;
}

export default MailSettingsDataTable;
