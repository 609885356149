import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";
import StepLayout from "../layout/StepLayout";

function EmployeeStepView({
  step,
  errors,
  setPageConfig,
  nextStep,
  previousStep,
  visitorData,
  employees,
  onEmployeeIdChange,
}) {
  const { translate } = useLanguageContext();

  return (
    <StepLayout
      step={step}
      setPageConfig={setPageConfig}
      nextStep={nextStep}
      previousStep={previousStep}
      proceedDisabled={!visitorData.employee_id || !employees}
    >
      <InputWrapper
        label={translate("eva.frontoffice.identification.employee")}
        error={EVAApiHelper.getResponseError("employee_id", errors)}
        required={true}
      >
        <Select
          value={visitorData.employee_id}
          options={
            employees
              ? employees.map((emp) => ({ value: emp.id, label: emp.name }))
              : []
          }
          onChange={onEmployeeIdChange}
        />
      </InputWrapper>
    </StepLayout>
  );
}

export default EmployeeStepView;
