import { useMemo } from "react";
import MultiSelectView from "./MultiSelectView";

function MultiSelect({
  value = [],
  options = [],
  searchable,
  sort,
  onChange = (val) => {},
  disabled,
  title,
  placeholder,
}) {
  /**
   * This method will determine which options are selected
   */
  const selectedOptions = useMemo(() => {
    return value
      .map((val) => options.find((option) => option.value === val))
      .filter((val) => val !== undefined && val !== null);
  }, [options, value]);

  // Determine which options can still be selected
  const availableOptions = useMemo(() => {
    return options.filter((option) => !value.includes(option.value));
  }, [options, value]);

  /**
   * This method will handle the value add event
   * @param {any} val
   */
  function onAdd(val) {
    const newValue = [...value, val];
    onChange(newValue);
  }

  /**
   * This method will handle the value remove event
   * @param {event} e
   * @param {any} val
   */
  function onRemove(e, val) {
    e.stopPropagation();
    const newValue = value.filter((valItem) => valItem !== val);
    onChange(newValue);
  }

  /**
   * This method will handle the clear event
   * @param {event} e
   */
  function onClear(e) {
    e.stopPropagation();
    onChange([]);
  }

  return (
    <MultiSelectView
      availableOptions={availableOptions}
      selectedOptions={selectedOptions}
      onAdd={onAdd}
      onRemove={onRemove}
      onClear={onClear}
      title={title}
      disabled={disabled}
      placeholder={placeholder}
      searchable={searchable}
      sort={sort}
    />
  );
}

export default MultiSelect;
