import { useParams } from "react-router-dom";
import QuestionPageView from "./QuestionPageView";
import { useState } from "react";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../../context/RouteContext";
import useEntityTranslations from "../../../../hooks/useEntityTranslations";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";

function QuestionPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { questionnaire, question } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [images, setImages] = useState([]);
  const [questionData, setQuestionData] = useState({
    fetched: false,
    questionnaire_id: questionnaire,
    image_id: null,
    name: "",
    order: 0,
    score: 0,
    group: "",
    translations: [],
  });

  const translationsController = useEntityTranslations(
    questionData.translations,
    onTranslationsChange
  );

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    fetchImages();
    if (question) {
      fetchQuestionData();
    }
  });

  /**
   * This method will fetch the publications data from the API
   */
  async function fetchQuestionData() {
    await callEvaApi(
      new EvaApiCall(`questions/${question}`)
        .setLoadingGroup("question_page_fetch")
        .setOnSuccess((response) => {
          setQuestionData({
            fetched: true,
            questionnaire_id: questionnaire,
            name: response.data.data.name,
            image_id: response.data.data.image,
            order: response.data.data.order,
            score: response.data.data.score,
            group: response.data.data.group,
            translations: response.data.data.translations.map(
              (translation) => ({
                language_id: translation.language,
                question: translation.question,
              })
            ),
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the images data from the API
   */
  async function fetchImages() {
    await callEvaApi(
      new EvaApiCall("files")
        .setParams({
          where: [{ column: "type", value: "image" }],
        })
        .setLoadingGroup("question_page_fetch")
        .setOnSuccess((response) => {
          setImages(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the image on input change
   * @param {any} imageId
   */
  function onImageChange(imageId) {
    setQuestionData((current) => ({ ...current, image_id: imageId }));
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setQuestionData((current) => ({ ...current, name: e.target.value }));
  }

  /**
   * This method will update the order on input change
   * @param {event} e
   */
  function onOrderChange(e) {
    setQuestionData((current) => ({ ...current, order: e.target.value }));
  }

  /**
   * This method will update the importance factor on input change
   * @param {event} e
   */
  function onScoreChange(e) {
    setQuestionData((current) => ({
      ...current,
      score: e.target.value,
    }));
  }

  /**
   * This method will update the group on input change
   * @param {event} e
   */
  function onGroupChange(e) {
    setQuestionData((current) => ({ ...current, group: e.target.value }));
  }

  /**
   * Handle the translations change
   * @param {object[]} value
   */
  function onTranslationsChange(value) {
    setQuestionData((current) => ({ ...current, translations: value }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(question ? `questions/${question}` : "questions")
        .setLoadingGroup("question_page_submit")
        .setMethod(question ? "PUT" : "POST")
        .setData(questionData, ["fetched"])
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <QuestionPageView
      question={question}
      questionData={questionData}
      images={images}
      translationsController={translationsController}
      onImageChange={onImageChange}
      onNameChange={onNameChange}
      onOrderChange={onOrderChange}
      onScoreChange={onScoreChange}
      onGroupChange={onGroupChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default QuestionPage;
