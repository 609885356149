import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../../context/LanguageContext";
import Select from "../Select";
import {
  StyledMultiSelect,
  StyledMultiSelectActions,
  StyledMultiSelectClear,
  StyledMultiSelectValue,
  StyledMultiSelectValues,
} from "./MultiSelectStyles";
import { faCaretDown, faXmark } from "@fortawesome/free-solid-svg-icons";

function MultiSelectView({
  availableOptions,
  selectedOptions,
  onAdd,
  onRemove,
  onClear,
  title,
  placeholder,
  disabled,
  searchable,
  sort,
}) {
  const { translate } = useLanguageContext();

  return (
    <Select
      title={title}
      placeholder={placeholder}
      disabled={disabled}
      options={availableOptions}
      onChange={onAdd}
      searchable={searchable}
      sort={sort}
      hideCaret={true}
      valueVisual={() => (
        <StyledMultiSelect>
          {selectedOptions.length > 0 ? (
            <StyledMultiSelectValues>
              {selectedOptions.map((option) => (
                <StyledMultiSelectValue
                  key={option.value}
                  onClick={(e) => onRemove(e, option.value)}
                >
                  {option.label}
                </StyledMultiSelectValue>
              ))}
            </StyledMultiSelectValues>
          ) : (
            translate("eva.main.general.select")
          )}

          <StyledMultiSelectActions>
            {selectedOptions.length > 0 && (
              <StyledMultiSelectClear
                icon={faXmark}
                size="sm"
                onClick={onClear}
                title={translate("eva.main.general.clear_all")}
              />
            )}

            <FontAwesomeIcon icon={faCaretDown} size="sm" />
          </StyledMultiSelectActions>
        </StyledMultiSelect>
      )}
    />
  );
}

export default MultiSelectView;
