import { useAuthContext } from "../../../../context/AuthContext";
import useDishCreateRouteConfig from "../../../../routes/configs/canteen/dishes/DishCreateRouteConfig";
import useDishEditRouteConfig from "../../../../routes/configs/canteen/dishes/DishEditRouteConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../../main/DataTable";
import useFileColumnConfig from "../../main/config/column/default/FileColumnConfig";

function DishesDataTable() {
  const authContext = useAuthContext();

  //Create the datatable config
  const config = new DataTableConfig("canteen_dishes");

  //Set the datatable fetch info
  config.setFetchRequest("canteen/dishes");
  config.setFetchParams({
    with_dish_location_data: true,
    with_dish_image_data: true,
  });

  //Set the datatable order by
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useFileColumnConfig("image"),
    useBasicColumnConfig("location.name"),
    useBasicColumnConfig("name").setLockActive(true),
    useBasicColumnConfig("description"),
    useBasicColumnConfig("price"),
    useBasicColumnConfig("capacity"),
    useBasicColumnConfig("calories").setActive(false),
    useBasicColumnConfig("health_score").setActive(false),
    useBasicColumnConfig("category").setActive(false),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("dish", useDishEditRouteConfig()),
    useDeleteRowActionConfig(
      "dish",
      (row) => `canteen/dishes/${row.id}`
    ).setDisabled((row) => !authContext.hasPermission("canteen.dishes.delete")),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("dish", useDishCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default DishesDataTable;
