import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { StyledAltIconButton } from "../../../../../styles/ButtonStyles";
import { StyledFormCard, StyledFormCardBody } from "../FlowPageFormStyles";
import {
  StyledHeaderActions,
  StyledStepFormCardHeader,
} from "./FlowPageStepFormStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FlowPageQuestionnaireForm from "./questionnaire/FlowPageQuestionnaireForm";
import FlowPageHouseruleForm from "./houserule/FlowPageHouseruleForm";
import FlowPageFieldsForm from "./fields/FlowPageFieldsForm";
import FlowPageMessagesForm from "./messages/FlowPageMessagesForm";

function FlowPageStepFormView({
  flowData,
  setFlowData,
  questionnaires,
  fields,
  houserules,
  messageTemplates,
  employees,
  onClose,
  onDelete,
  submitError,
}) {
  const { translate } = useLanguageContext();
  const StepElement = getStepFormElement();

  function getStepFormElement() {
    switch (flowData.steps[flowData.selectedStep].type) {
      case "questionnaire":
        return FlowPageQuestionnaireForm;
      case "fields":
        return FlowPageFieldsForm;
      case "houserule":
        return FlowPageHouseruleForm;
      case "messages":
        return FlowPageMessagesForm;
      default:
        function EmptyStepForm() {
          return (
            <div style={{ whiteSpace: "wrap" }}>
              {translate("eva.main.messages.flow.empty_step")}
            </div>
          );
        }
        return EmptyStepForm;
    }
  }

  return (
    <StyledFormCard>
      <StyledStepFormCardHeader>
        <h2>
          {`${flowData.selectedStep + 1}. ${translate(
            "eva.main.entities.states.edit",
            [
              {
                key: "entities",
                value: translate(
                  `eva.main.entities.${
                    flowData.steps[flowData.selectedStep].type
                  }`
                ),
              },
            ]
          )}`}
        </h2>

        <StyledHeaderActions>
          <StyledAltIconButton
            onClick={onClose}
            title={translate("eva.main.general.close")}
          >
            <FontAwesomeIcon icon={faXmark} />
          </StyledAltIconButton>

          <StyledAltIconButton
            onClick={onDelete}
            title={translate("eva.main.entities.states.delete", [
              { key: "entities", value: translate("eva.main.general.step") },
            ])}
          >
            <FontAwesomeIcon icon={faTrash} />
          </StyledAltIconButton>
        </StyledHeaderActions>
      </StyledStepFormCardHeader>

      <StyledFormCardBody>
        <StepElement
          flowData={flowData}
          setFlowData={setFlowData}
          questionnaires={questionnaires}
          fields={fields}
          houserules={houserules}
          messageTemplates={messageTemplates}
          employees={employees}
          submitError={submitError}
        />
      </StyledFormCardBody>
    </StyledFormCard>
  );
}

export default FlowPageStepFormView;
