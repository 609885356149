import { useLanguageContext } from "../../../../../../context/LanguageContext";
import FilePreview from "../../../../../filePreview/FilePreview";
import ColumnConfig from "../ColumnConfig";

/**
 * @param {string} key default value is "image"
 * @param {function(row) : any} data default value is (row) => row[key]
 * @returns {ColumnConfig}
 */
function useFileColumnConfig(
  key = "file",
  data = (row) => row[key],
  translation = `${key}_id`
) {
  const { translate, translateEntity } = useLanguageContext();

  return new ColumnConfig()
    .setKey(key)
    .setData(
      (row) =>
        data(row) &&
        typeof data(row) === "object" && (
          <FilePreview path={translateEntity(data(row), "path")} />
        )
    )
    .setLabel(translate(`eva.main.validation.attributes.${translation}`))
    .setLabelHeaderHidden(true)
    .setHeaderStyle({ width: "0px" })
    .setOrderable(false)
    .setSearchable(false);
}

export default useFileColumnConfig;
