import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FlowChart from "../../../../components/flowChart/FlowChart";
import Select from "../../../../components/select/main/Select";
import { faEnvelope, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  StyledAddButton,
  StyledNode,
  StyledNodeMessage,
} from "./FlowPageChartStyles";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useTheme } from "styled-components";
import Draggable from "../../../../components/draggable/Draggable";
import { StyledNodeTitle } from "../../../../components/flowChart/node/FlowChartNodeStyles";
import CompactError from "../../../../components/error/compact/CompactError";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";

function FlowPageChartView({
  flowData,
  onAddStep,
  onStepClick,
  onDragTargetEnter,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();
  const spacing = 100;

  function getAddButton(addIndex) {
    return (
      <Select
        title={translate("eva.main.general.add")}
        as={StyledAddButton}
        value={null}
        valueVisual={() => <FontAwesomeIcon icon={faPlus} />}
        hideCaret={true}
        options={[
          {
            value: "questionnaire",
            label: translate("eva.main.entities.questionnaire"),
          },
          { value: "fields", label: translate("eva.main.entities.fields") },
          {
            value: "houserule",
            label: translate("eva.main.entities.houserule"),
          },
          {
            value: "messages",
            label: translate("eva.main.entities.messages"),
          },
          {
            value: "employee",
            label: translate("eva.main.entities.employee"),
          },
        ]}
        onChange={(value) => onAddStep(value, addIndex)}
      />
    );
  }

  function getEdges() {
    // Start -> First step or end
    const edges = [
      {
        from: "start",
        to: flowData.steps.length > 0 ? "STEP_0" : "end",
        element: getAddButton(0),
      },
    ];

    flowData.steps.forEach((step, index) => {
      // Add edge to next step
      edges.push({
        from: `STEP_${index}`,
        to: index === flowData.steps.length - 1 ? "end" : `STEP_${index + 1}`,
        element: getAddButton(index + 1),
        color:
          index === flowData.selectedStep ? theme.colors.accent.normal : null,
        zIndex: index === flowData.selectedStep ? 1 : null,
      });

      // Add edge to on_fail_step
      if (
        (step.on_fail_step !== null && step.on_fail_step !== undefined) ||
        step.on_fail === "stop"
      ) {
        edges.push({
          from: `STEP_${index}`,
          to: step.on_fail === "stop" ? "end" : `STEP_${step.on_fail_step}`,
          fromPort: "left",
          toPort: "left",
          color:
            index === flowData.selectedStep ? theme.colors.accent.normal : null,
          zIndex: index === flowData.selectedStep ? 1 : null,
        });
      }
    });

    return edges;
  }

  return (
    <FlowChart
      nodes={[
        // Start
        {
          id: "start",
          pos: { x: 0, y: 0 },
          title: "Start",
          style: {
            backgroundColor: theme.colors.primary.darkest,
          },
        },

        // Steps
        ...flowData.steps.map((step, index) => ({
          id: `STEP_${index}`,
          pos: { x: 0, y: spacing + index * spacing },
          element: (
            <Draggable id={`STEP_${index}`} onTargetEnter={onDragTargetEnter}>
              <StyledNode
                onClick={() => onStepClick(index)}
                focused={flowData.selectedStep === index}
                style={{ position: "relative" }}
              >
                <StyledNodeTitle>
                  {`${index + 1}. ${translate(
                    `eva.main.entities.${step.type}`
                  )}`}

                  <CompactError
                    error={EVAApiHelper.getResponseErrorsLike(
                      `steps.${index}`,
                      submitError,
                      translate
                    )}
                  />
                </StyledNodeTitle>

                {step.messages?.length > 0 && (
                  <StyledNodeMessage focused={flowData.selectedStep === index}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </StyledNodeMessage>
                )}
              </StyledNode>
            </Draggable>
          ),
        })),

        // End
        {
          id: "end",
          pos: { x: 0, y: spacing + flowData.steps.length * spacing },
          title: "End",
          style: { backgroundColor: theme.colors.primary.darkest },
        },
      ]}
      edges={getEdges()}
    />
  );
}

export default FlowPageChartView;
