import { useState } from "react";
import { useHelpTextContext } from "../../../../context/HelpTextContext";
import HelpSelectView from "./HelpSelectView";

function HelpSelect({ disabled }) {
  const helpTextContext = useHelpTextContext();
  const helpTexts = helpTextContext.getCurrentHelpTexts();
  const [helpText, setHelpText] = useState(null);

  /**
   * Handle the on change event
   * @param {any} value
   */
  function onChange(value) {
    setHelpText(helpTexts.find((helpText) => helpText.id === value));
  }

  return (
    <HelpSelectView
      helpText={helpText}
      helpTexts={helpTexts}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

export default HelpSelect;
