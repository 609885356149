import styled from "styled-components";
import { StyledCard, StyledCardBody } from "../../../../styles/CardStyles";

export const StyledFormCard = styled(StyledCard)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledFormCardBody = styled(StyledCardBody)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  width: 400px;
  gap: ${(props) => props.theme.spacing.normal};
`;
