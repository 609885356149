import { StyledInput } from "../../../../styles/InputStyles";
import Select from "../../../select/main/Select";
import { StyledHtmlSizeInput } from "./HtmlSizeInputStyles";

function HtmlSizeInputView({
  size,
  type,
  types,
  withNullType,
  onSizeChange,
  onTypeChange,
}) {
  return (
    <StyledHtmlSizeInput>
      {type && type !== "auto" && (
        <StyledInput
          type="number"
          placeholder="0"
          value={size}
          onChange={onSizeChange}
          style={{ width: "100%" }}
        />
      )}

      <Select
        options={types.map((type) => ({
          value: type,
          label: type,
        }))}
        nullable={withNullType}
        value={type}
        onChange={onTypeChange}
      />
    </StyledHtmlSizeInput>
  );
}

export default HtmlSizeInputView;
