import styled from "styled-components";

export const StyledImageSelect = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledName = styled.small`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) =>
    `rgba(${props.theme.colors.primary.darkestRGB}, 0.33)`};
  color: ${(props) => props.theme.colors.on.primary};
  text-align: center;
  padding: ${(props) => props.theme.spacing.small};
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
