import FlowFieldsView from "./FlowFieldsView";

function FlowFields({
  step,
  data = {},
  setData = () => {},
  errors,
  frontoffice = true,
}) {
  /**
   * This method will handle the field change event
   * @param {object} stepField
   * @param {any} value
   */
  function onFieldChange(stepField, value) {
    setData({
      ...data,
      [`field_${stepField.field.id}`]: value,
    });
  }

  return (
    <FlowFieldsView
      step={step}
      data={data}
      errors={errors}
      onFieldChange={onFieldChange}
      frontoffice={frontoffice}
    />
  );
}

export default FlowFields;
