import { useTheme } from "styled-components";
import FilePreview from "../../../../../../components/filePreview/FilePreview";
import Checkbox from "../../../../../../components/input/checkbox/Checkbox";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { StyledFlexLayout } from "../../../../../../styles/LayoutStyles";
import StepLayout from "../layout/StepLayout";

function HouseruleStepView({
  step,
  houseruleData,
  setPageConfig,
  nextStep,
  previousStep,
  onRead,
  onAcceptChange,
}) {
  const theme = useTheme();
  const { translateEntity, translate } = useLanguageContext();

  return (
    <StepLayout
      step={step}
      setPageConfig={setPageConfig}
      nextStep={nextStep}
      previousStep={previousStep}
      proceedDisabled={!houseruleData?.accepted}
    >
      <StyledFlexLayout style={{ flexDirection: "column" }}>
        <FilePreview
          path={translateEntity(step.file, "path")}
          onCloseCallback={onRead}
        >
          <p>
            {translate("eva.frontoffice.houserules.question", [
              {
                key: "houserules",
                value: (
                  <span
                    style={{
                      fontWeight: "bold",
                      color: theme["settings.button.color.primary.background"],
                    }}
                  >
                    {translate("eva.frontoffice.houserules").toLowerCase()}
                  </span>
                ),
              },
            ])}
          </p>
        </FilePreview>

        <StyledFlexLayout style={{ alignItems: "center" }}>
          <Checkbox
            value={houseruleData?.accepted}
            disabled={!houseruleData?.read && !houseruleData?.accepted}
            label={translate("eva.frontoffice.houserules.accept")}
            onChange={onAcceptChange}
          />
        </StyledFlexLayout>
      </StyledFlexLayout>
    </StepLayout>
  );
}

export default HouseruleStepView;
