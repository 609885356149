import StepLayout from "../layout/StepLayout";
import FlowFields from "../../../../../../components/flowFields/FlowFields";

function FieldsStepView({
  step,
  errors,
  visitorData,
  setVisitorData,
  setPageConfig,
  nextStep,
  previousStep,
}) {
  return (
    <StepLayout
      step={step}
      setPageConfig={setPageConfig}
      nextStep={nextStep}
      previousStep={previousStep}
    >
      <FlowFields
        step={step}
        data={visitorData}
        setData={setVisitorData}
        errors={errors}
      />
    </StepLayout>
  );
}

export default FieldsStepView;
